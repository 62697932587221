import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from '../../../config/i18next';

const StripePricingTable = () => {
    const [language, selectLanguage] = useState('en');
    const { t } = useTranslation();
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        selectLanguage(i18next.language);
    }, [t]);

    return language === t('en')
        ? React.createElement('stripe-pricing-table', {
              'pricing-table-id': 'prctbl_1OLnGiGwflIQWs8UTnPpQ5W7',
              'publishable-key':
                  'pk_live_51NT0ZsGwflIQWs8UrhdOki33RTAvq7c9Joeju501tpxmBgLpPQQ72JFaYUKRTAwudXOUTmzhmOdTMYQR5apPtaCs00EmOGDbYB',
          })
        : React.createElement('stripe-pricing-table', {
              'pricing-table-id': 'prctbl_1OQVczGwflIQWs8U2GPPQK56',
              'publishable-key':
                  'pk_live_51NT0ZsGwflIQWs8UrhdOki33RTAvq7c9Joeju501tpxmBgLpPQQ72JFaYUKRTAwudXOUTmzhmOdTMYQR5apPtaCs00EmOGDbYB',
          });
};
export default StripePricingTable;
