import React from 'react';
import './CollapseCommand.css';

const CollapseCommand = ({ isShow }: { isShow: Boolean }) => (
    <svg name="collapseCommand" className={isShow ? 'svg' : 'isClose'}>
        <rect x="6" y="0" width="8.5" height="1.5" rx="1" transform="rotate(45)" />
        <rect x="-1" y="13" width="8.5" height="1.5" rx="1" transform="rotate(-45)" />
    </svg>
);

export default CollapseCommand;
