interface configType {
    API_URL: string;
    BASE_URL: string;
    CHAT_URL: string;
    NOTIFICATION_URL: string;
    hotjar_site_id: number;
    hotjar_snippet_version: number;
}

const API_URL = window?._env_?.REACT_APP_API_URL
    ? `${window?._env_?.REACT_APP_API_URL}/api`
    : 'https://test-api.reelcruit.com/api';
const CHAT_URL = window?._env_?.REACT_APP_API_URL
    ? `${window?._env_?.REACT_APP_API_URL}/chat`
    : 'https://test-api.reelcruit.com/chat';
const NOTIFICATION_URL = window?._env_?.REACT_APP_API_URL
    ? `${window?._env_?.REACT_APP_API_URL}/notifications`
    : 'https://test-api.reelcruit.com/notifications';

const hotjar_site_id = 3832499;
const hotjar_snippet_version = 6;

const config: configType = {
    API_URL: API_URL,
    BASE_URL: window?.location.origin || 'http://localhost:3000/',
    CHAT_URL: CHAT_URL,
    NOTIFICATION_URL: NOTIFICATION_URL,
    hotjar_site_id: hotjar_site_id,
    hotjar_snippet_version: hotjar_snippet_version,
};



declare global {
    interface Window {
        _env_: any;
        h:any;
        _hjSettings:any;
    }
}

export default config;
