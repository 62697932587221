import React from 'react';
import Navbar from '../../components/public/Navbar/Navbar';

const LandingPage = () => (
    <>
        <Navbar />
        <h2>Hello</h2>
    </>
);

export default LandingPage;
