/* eslint-disable max-len */
/* eslint$disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Switch, Divider, Grid, Typography, useMediaQuery, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/system';
import UserMatch from '../../models/userMatch';
import PercentageDoughnut from '../common/PercentageDoughnut';
import globalStyles from '../../config/globalCss';
import { getTypeOfWorkDisplay, formatCurrencyDisplay } from '../helpers/utilityFunctions';
import { PERMANENT_ROLE } from '../../config/constants';
import { Skills } from '../../models/skills';
import { getOptionsLabeled, LEVEL_LANGUAGE_OPTIONS } from '../helpers/typeOptions';
import { SmallNextIcon, ArrowDownIcon } from '../icons/Icons';
import CollapseCommand from './CollapseCommand/CollapseCommand';

import ReduceCommand from './ReduceCommand/ReduceCommand';

const styles: any = makeStyles((theme: Theme) => ({
    matchType: {
        width: 'fit-content',
        textAlign: 'justify',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '25px',
        display: 'flex',
    },
    contentDesc: {
        paddingTop: '40px',
    },
    titleDesc: {
        fontFamily: 'SF Pro Display',
        fontStyle: 'normal',
        fontSize: '26px',
        fontWeight: 400,
        marginTop: 20,
        marginBottom: 20,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    subTitleDesc: {
        fontFamily: 'SF Pro Display',
        fontStyle: 'normal',

        fontSize: '20px',
        fontWeight: 400,
        marginTop: 10,
        marginBottom: 10,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    descBackground: {
        width: 'fit-content',
        height: 40,
        backgroundColor: '#DBEEF8',
        borderRadius: 8,
        color: '#0E71A7',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px 5px 10px',
        marginTop: '4px',
    },
    descBackgroundMatched: {
        width: 'fit-content',
        height: 40,
        backgroundColor: '#DBEEF8',
        borderRadius: 8,
        color: '#2E7D32',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px 5px 10px',
        marginTop: '4px',
    },
    gridSpace: {
        paddingTop: '24px',
    },
    summary: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        color: theme.palette.common.black,
    },
    summaryColor: {
        color: '#128DD1',
    },
    container: {
        maxHeight: '508px',
        maxWidth: '830px',
    },
}));

const CookiePreferencesView = () => {
    const { t } = useTranslation();
    const css = styles();
    const classes = globalStyles();
    const [openStrictlyCookies, setOpenStrictlyCookies] = useState(false);
    const [openFunctionalCookies, setOpenFunctionalCookies] = useState(false);
    const [openPerformanceCookies, setOpenPerformanceCookies] = useState(false);
    const [openTargetingCookies, setOpenTargetingCookies] = useState(false);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
        noSsr: true,
    });

    return (
        <Grid container className={css.container}>
            <Grid item xs={12}>
                <Typography className={css.titleDesc}>{t('Setyourcookiepreference')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={css.matchType}>{t('CookiesDescription')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={css.titleDesc}>{t('Manageconsentpreferences')}</Typography>
            </Grid>
            <Grid container direction="row">
                <Grid container>
                    <Grid item xs={isMobile ? 8 : 10}>
                        <Typography className={css.subTitleDesc}>{t('Strictlynecessarycookies')}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <Switch defaultChecked />
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <IconButton onClick={() => setOpenStrictlyCookies(!openStrictlyCookies)}>
                            <CollapseCommand isShow={!openStrictlyCookies} />
                            <ReduceCommand isShow={openStrictlyCookies} />
                        </IconButton>
                    </Grid>
                </Grid>
                {openStrictlyCookies && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: '20px',
                        }}>
                        <Typography className={css.matchType}>{t('StrictlyDescription')}</Typography>
                    </Grid>
                )}
                <Divider sx={{ color: '#EFE7EC', marginTop: '16px' }} />
                <Grid container>
                    <Grid item xs={isMobile ? 8 : 10}>
                        <Typography className={css.subTitleDesc}>{t('Functionalcookies')}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <Switch defaultChecked />
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <IconButton onClick={() => setOpenFunctionalCookies(!openFunctionalCookies)}>
                            <CollapseCommand isShow={!openFunctionalCookies} />
                            <ReduceCommand isShow={openFunctionalCookies} />
                        </IconButton>
                    </Grid>
                </Grid>
                {openFunctionalCookies && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: '20px',
                        }}>
                        <Typography className={css.matchType}>{t('FunctionalDescription')}</Typography>
                    </Grid>
                )}
                <Divider sx={{ color: '#EFE7EC', marginTop: '16px' }} />
                <Grid container>
                    <Grid item xs={isMobile ? 8 : 10}>
                        <Typography className={css.subTitleDesc}>{t('Performancecookies')}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <Switch defaultChecked />
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <IconButton onClick={() => setOpenPerformanceCookies(!openPerformanceCookies)}>
                            <CollapseCommand isShow={!openPerformanceCookies} />
                            <ReduceCommand isShow={openPerformanceCookies} />
                        </IconButton>
                    </Grid>
                </Grid>
                {openPerformanceCookies && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: '20px',
                        }}>
                        <Typography className={css.matchType}>{t('PerformanceDescription')}</Typography>
                    </Grid>
                )}
                <Divider sx={{ color: '#EFE7EC', marginTop: '16px' }} />
                <Grid container>
                    <Grid item xs={isMobile ? 8 : 10}>
                        <Typography className={css.subTitleDesc}>{t('Targetingcookies')}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <Switch defaultChecked />
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} textAlign="right" className={css.edit}>
                        <IconButton onClick={() => setOpenTargetingCookies(!openTargetingCookies)}>
                            <CollapseCommand isShow={!openTargetingCookies} />
                            <ReduceCommand isShow={openTargetingCookies} />
                        </IconButton>
                    </Grid>
                </Grid>
                {openTargetingCookies && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: '20px',
                        }}>
                        <Typography className={css.matchType}>{t('TargetingDescription')}</Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default CookiePreferencesView;
