// CookiePreferences.js
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Link, useMediaQuery } from '@mui/material';
import { PRIVACY_POLICY } from '../../routes/routes';
import StyledBtnComponent from '../common/StyledBtnComponent';
import DialogMsg from '../common/DialogMsg';
import globalStyles from '../../config/globalCss';
import CookiePreferencesView from './CookiePreferencesView';

const styles: any = makeStyles((theme: Theme) => ({
    txt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontSize: '1rem',
        lineHeight: '30px',
        color: '#fff',
        textAlign: 'justify',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        justifyContent: 'flex-start',
        justifySelf: 'start',
    },
}));

const CookiePreferences = () => {
    const classes = globalStyles();
    const css = styles();
    const { t } = useTranslation();
    const [openPreferenceCookies, setOpenPreferenceCookies] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [, setCookie] = useCookies(['cookieConsent']);

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 10); // Expires in 10 years

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
        noSsr: true,
    });

    const handleAcceptCookies = () => {
        setCookie(
            'cookieConsent',
            {
                all: true,
            },
            { path: '/', expires }
        );
    };

    const handlePreferencesCookies = () => {
        setOpenPreferenceCookies(true);
    };

    const handleClose = () => {
        setOpenPreferenceCookies(false);
    };

    const handleRejectAll = () => {
        setOpenPreferenceCookies(false);
    };

    const handleConfirmChoices = () => {
        setOpenPreferenceCookies(false);
        setCookie(
            'cookieConsent',
            {
                preferences: Math.random().toString(36),
            },
            { path: '/' }
        );
    };

    const ActionCookiesComponent = (
        <Grid className={css.actionContainer} container>
            <Grid item xs={isMobile ? 12 : 2}>
                <StyledBtnComponent
                    title={t('Rejectall')}
                    handleOnClick={handleRejectAll}
                    classesName={classes.btnUnderLineLinks}
                />
            </Grid>

            <Grid item xs={isMobile ? 12 : 10}>
                <StyledBtnComponent title={t('Confirmmychoices')} handleOnClick={handleConfirmChoices} red />
            </Grid>
        </Grid>
    );

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: 'white',
                minHeight: '180px',
            }}>
            <Grid
                component="footer"
                sx={{
                    textAlign: 'center',
                    paddingTop: '50px',
                    borderColor: 'white',
                    minHeight: '180px',
                    width: '1200px',
                }}
                container>
                <Grid
                    item
                    xs={isMobile ? 12 : 7}
                    sx={{
                        textAlign: 'left',
                    }}>
                    <Typography className={css.txt}>{t('ByclickingCookies')}</Typography>
                    <Link
                        style={{
                            padding: '10px',
                        }}
                        href={PRIVACY_POLICY}>
                        {t('privacyPolicy')}
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={isMobile ? 12 : 2.5}
                    sx={{
                        padding: '10px',
                    }}>
                    <StyledBtnComponent
                        title={t('CookiesPreferences')}
                        handleOnClick={handlePreferencesCookies}
                        red={false}
                        classesName={classes.btnLink}
                    />
                </Grid>
                <Grid
                    item
                    xs={isMobile ? 12 : 2.5}
                    sx={{
                        padding: '10px',
                    }}>
                    <StyledBtnComponent
                        title={t('AcceptallCookies')}
                        handleOnClick={handleAcceptCookies}
                        classesName={classes.btnContained}
                    />
                </Grid>
            </Grid>
            <DialogMsg
                open={openPreferenceCookies}
                title={t('Reelcruit')}
                handleClose={handleClose}
                isAction
                ActionComponent={ActionCookiesComponent}>
                <CookiePreferencesView />
            </DialogMsg>
        </div>
    );
};

export default CookiePreferences;
