import React from 'react';
import './ReduceCommand.css';

const ReduceCommand = ({ isShow }: { isShow: Boolean }) => (
    <svg className={isShow ? 'svg' : 'isClose'} style={{ paddingTop: 0, marginTop: -5 }}>
        <rect x="13" y="0" width="8.5" height="1.5" rx="1" transform="rotate(45)" />
        <rect x="-9" y="13" width="8.5" height="1.5" rx="1" transform="rotate(-45)" />
    </svg>
);

export default ReduceCommand;
