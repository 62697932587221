/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import pricings, { conditions } from './data';
import StripePricingTable from './pricing-page';

import '../styles/content/NewPricing.scss';

type SubPrice = {
    price: string;
    services: string[];
    description: string;
};

interface PricingCardProps {
    title: string;
    permanent: SubPrice;
    consulting: SubPrice;
}
// component for pricing card
const PricingCard = ({ title, permanent, consulting }: PricingCardProps) => {
    const [tab, setTab] = useState('permanent');
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [btnAnchorEl, setBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const btnOpen = Boolean(btnAnchorEl);

    const onHover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        setBtnAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleBtnClose = () => {
        setBtnAnchorEl(null);
    };

    const subPrice = useMemo(() => (tab === 'permanent' ? permanent : consulting), [tab]);

    const renderList = useCallback(
        () =>
            subPrice.services.map((item) => (
                <li key={item} className="new-pricing__list-item">
                    <Check color="primary" sx={{ fontSize: 16 }} />
                    <span>{t(item)}</span>
                </li>
            )),
        [subPrice]
    );

    return (
        <div key={title} className="new-pricing__card">
            <div className="new-pricing__title-container">
                <h2 className="new-pricing__title">{t(title)}</h2>

                {title === 'Startups' && (
                    <div className="new-pricing__icon">
                        <div onMouseEnter={(event) => (title === 'Startups' ? onHover(event) : {})}>
                            <InfoOutlinedIcon />
                        </div>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            elevation={0}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        left: '50%',
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) translateX(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <div className="new-pricing__info-details">
                                <span className="new-pricing__info-details__title">
                                    {t('Startups Program Conditions')}
                                </span>

                                {conditions.map((item, index) => (
                                    <div key={index} className="new-pricing__info-details__item">
                                        <CheckBoxIcon fontSize="small" color="primary" />
                                        <span className="new-pricing__info-details__item-text">{t(item)}</span>
                                    </div>
                                ))}
                            </div>
                        </Menu>
                    </div>
                )}
            </div>

            <div className="new-pricing__tabs">
                {['permanent', 'consulting'].map((item) => (
                    <button
                        key={item}
                        type="button"
                        className={`new-pricing__tab ${tab === item ? 'new-pricing__tab--selected' : ''}`}
                        onClick={() => setTab(item)}>
                        {t(item)}
                    </button>
                ))}
            </div>

            <h3 className={`new-pricing__price ${subPrice.price.trim() === '' ? 'new-pricing__price--small' : ''}`}>
                {subPrice.price.trim() === '' ? 'Get more' : subPrice.price}
            </h3>

            <Button sx={{ textTransform: 'capitalize', py: 2 }} disableElevation variant="contained" onClick={onClick}>
                {t('Get Started')}
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={btnAnchorEl}
                open={btnOpen}
                onClose={handleBtnClose}
                aria-labelledby="basic-demo-button">
                <MenuItem
                    sx={{ minWidth: 250 }}
                    onClick={() => window.open('https://meetings.hubspot.com/frederik-gauthier', '_blank')}>
                    Québec {t('onlyForEmploye')}
                </MenuItem>
                <MenuItem
                    sx={{ minWidth: 250 }}
                    onClick={() => window.open('https://calendly.com/reelcruitralph', '_blank')}>
                    {t('Other provinces')} {t('onlyForEmploye')}
                </MenuItem>
            </Menu>

            <hr />

            <span>{t('Reelcruit services include the following:')}</span>

            <ul className="new-pricing__list">{renderList()}</ul>

            <p className="new-pricing__description">{t(subPrice.description)}</p>

            <a href="/terms-and-condition" className="new-pricing__terms">
                {t('Terms of service')}
            </a>
        </div>
    );
};

const NewPricing = () => <StripePricingTable />;

export default NewPricing;
