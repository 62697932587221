import React from 'react';
import { useCookies } from 'react-cookie';
import './App.css';
import CookiePreferences from './components/landingPage/CookiePreferences';

import './assets/scss/Saas.scss';
import RoutesComponent from './routes/RoutesComponent';

const App = () => {
    const [cookie] = useCookies(['cookieConsent']);

    return (
        <div className="App">
            <RoutesComponent />
            {!cookie.cookieConsent && (
                <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#0B1B39' }}>
                    <CookiePreferences />
                </div>
            )}
        </div>
    );
};

export default App;
